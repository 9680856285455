import packageInfo from "../../package.json";

export const environment = {
  version: packageInfo.version,
  production: true,
  url_api: 'https://dashboard.api.ultrapay.digital/api/v1',
  auth0: {
    domain: "ultrapay.eu.auth0.com",
    clientId: "M0ihdJMEuAtxscDJOoyu6d2qzkSFESU9",
    audience: "https://ultrapay.eu.auth0.com/api/v2/",
  }
};
